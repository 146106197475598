export default {
    setCurrRoute(context, route) {
        //don't need auth route, keep route in case of refresh
        // Also don't save the loading route, that would be dumb
        if (!route.meta.noSave) {
            localStorage.setItem("currRoute", route.path);
            context.commit("setCurrRoute", route.path);
        }
    },
    setSideBarStatus(context, isOpen) {
        context.commit("setSideBarStatus", isOpen);
    },
    loading(context, isLoading) {
        context.commit("loading", isLoading);
    }

  
};
