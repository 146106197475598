<template>
  <div class="relative z-10 flex flex-shrink-0 h-16 bg-white shadow dark:bg-gray-500">
    <button
      type="button"
      class="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-akf-200 md:hidden"
      @click="setSideBar(true)"
    >
      <span class="sr-only">Open sidebar</span>
      <MenuAlt2Icon class="w-6 h-6" aria-hidden="true" />
    </button>
    <div class="flex justify-between flex-1 px-4">
      <div class="flex flex-1">
        <form class="flex w-full md:ml-0" action="#" method="GET">
          <label for="search-field" class="sr-only">Search</label>
          <div class="relative w-full text-gray-400 focus-within:text-gray-600">
            <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
              <SearchIcon class="w-5 h-5" aria-hidden="true" />
            </div>
            <input
              id="search-field"
              class="block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 border-transparent dark:placeholder-gray-200 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm dark:bg-gray-500"
              placeholder="Search"
              type="search"
              name="search"
            />
          </div>
        </form>
      </div>
      <div class="flex items-center ml-4 md:ml-6">
        <button
          type="button"
          class="p-1 text-gray-400 bg-white rounded-full dark:bg-gray-500 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-akf-200"
        >
          <span class="sr-only">View notifications</span>
          <BellIcon class="w-6 h-6" aria-hidden="true" />
        </button>

        <!-- Profile dropdown -->
        <Menu as="div" class="relative ml-3">
          <div>
            <MenuButton
              class="flex items-center max-w-xs text-sm bg-white rounded-full dark:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-akf-200"
            >
              <span class="sr-only">Open user menu</span>
              <Avatar />
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <MenuItems
              class="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <MenuItem v-slot="{ active }">
                <router-link
                  :to="{name: 'Logout', params: {}}"
                  :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                  >Logout</router-link>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <router-link :to="{ name: 'Profile', params: { userId: uid }}" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 test-sm text-gray-700']">Profile</router-link>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
  </div>
</template>

<script>
  import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
  import { BellIcon, MenuAlt2Icon } from "@heroicons/vue/outline";
  import { SearchIcon } from "@heroicons/vue/solid";
  import { auth } from "@/firebase"

  import Avatar from "./Avatar";

  export default {
    name: "topBar",
    components: {
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      BellIcon,
      MenuAlt2Icon,
      SearchIcon,
      Avatar,
    },
    data() {
      return {
        darkMode: localStorage.dark,
        uid: auth.currentUser.uid
      };
    },
    methods: {
      setSideBar(isOpen) {
        this.$store.dispatch("setSideBarStatus", isOpen);
      },
      dark() {
        this.darkMode = !this.darkMode;
        localStorage.dark = this.darkMode;
        console.log(localStorage.dark);
      },
    },
  };
</script>
