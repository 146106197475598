<template>
    <div class="authHeader">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
  name: 'authHeader',
  components: {
  },
  data: function () {
    return {
    }
  }
}
</script>