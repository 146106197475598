<template>
  <button :type="type" :class="mode" class="btnBase">
    <slot></slot>
  </button>
</template>

<script>
  export default {
    props: ["type", "mode"],
  };
</script>

<style scoped>
  .btnBase {
    @apply inline-flex items-center px-3 py-2 border shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-akf-100;
  }
  .success {
    @apply bg-green-400 hover:bg-green-500 border-transparent text-white;
  }
  .warning {
    @apply bg-akfwarning-100 hover:bg-yellow-600 border-transparent text-white;
  }
  .alert {
    @apply bg-akfalert-100 hover:bg-red-800 border-transparent text-white;
  }
  .flat {
    @apply border-gray-300 text-gray-700 bg-white hover:bg-gray-100;
  }
</style>
