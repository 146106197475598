<template>
  <div class="flex h-screen overflow-hidden bg-gray-100 dark:bg-gray-700">
    <side-bar></side-bar>
    <div class="flex flex-col flex-1 w-0 overflow-hidden">
      <top-bar></top-bar>
      <Loading v-if="$store.getters.isLoading" />
      <the-content></the-content>
    </div>
  </div>
  <!-- adding modal component when session is idle, for possible auto -->
  <modal-idle v-if="isIdle" />
</template>

<script>
  import SideBar from "@/components/layout/SideBar.vue";
  import TopBar from "@/components/layout/TopBar.vue";
  import TheContent from "@/components/layout/TheContent.vue";
  import ModalIdle from "@/components/UI/ModalIdle.vue";
  import Loading from "@/components/UI/Loading.vue";

  export default {
    name: "blank",
    components: {
      SideBar,
      TopBar,
      TheContent,
      ModalIdle,
      Loading,
    },
    computed: {
      isIdle() {
        return this.$store.state.idleVue.isIdle;
      },
    },
  };
</script>
