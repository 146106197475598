import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import router from "./router";
import store from "./store";
import axios from 'axios';
import IdleVue from "idle-vue";
import BaseDialog from './components/UI/BaseDialog.vue';
import BaseButton from './components/UI/BaseButton.vue';
import './assets/tailwind.css'
import MicrosoftDataService from "./services/MicrosoftDataService";

// setting idle logout 
const idleOptions = {
  store,
  idleTime: (3600000), // 1hr idle to logout
  startAtIdle: false
};

const app = createApp(App);


app.use(store);
app.use(router);
app.use(IdleVue, idleOptions);


/************************************ MS API Config ***************************/
var msAPI = axios.create({
    baseURL: 'https://graph.microsoft.com/v1.0'
});

app.config.globalProperties.$httpMS=msAPI

// used when axios request starts
msAPI.interceptors.request.use(
    async config => {
      const token = await MicrosoftDataService.getToken()
      if (token) {
        config.headers.common["Authorization"] = 'Bearer ' + token;
      }
      store.dispatch('loading', true)
      return config;
      
    },
    error => {
      store.dispatch('loading', false)
      return Promise.reject(error);
    }
  );

// used when axios request ends
msAPI.interceptors.response.use(
  response => {
      store.dispatch('loading', false)        
    return response;
  },
  error => {
    store.dispatch('loading', false)
    return Promise.reject(error);
  }
);

/************************************ MS API Config ***************************/

app.component('base-dialog', BaseDialog);
app.component('base-button', BaseButton);

app.mount('#app')

