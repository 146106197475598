<template>
    <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <div class="py-6">
            <div class="max-w-7xl px-4 sm:px-6 md:px-8">
            <!-- content main -->
            <div class="py-4">
                <router-view></router-view>
            </div>
            <!-- /End main content -->
            </div>
        </div>
    </main>
</template>

<script>
export default {
  name: 'content',
  components: {
  },
  data: function () {
    return {
    }
  }
}
</script>