export default {
    getCurrRoute(state) {
        return state.currRoute;
    },
    getSideBarStatus(state) {
        return state.sidebarOpen
    },
    isLoading(state) {
        return state.isLoading
    }
  };