<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="fixed inset-0 z-40 flex md:hidden" @close="setSideBar(false)">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full"
      >
        <div class="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-gray-800 ">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="absolute top-0 right-0 pt-2 -mr-12">
              <button
                type="button"
                class="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                @click="setSideBar(false)"
              >
                <span class="sr-only">Close sidebar</span>
                <XIcon class="w-6 h-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>
          <div class="flex items-center flex-shrink-0 px-4">
            <img class="w-auto h-10 mx-auto" src="@/assets/img/akf-logo-white.png" alt="AKF-Dashboard" />
          </div>
          <div class="flex-1 h-0 mt-5 overflow-y-auto">
            <nav class="px-2 space-y-1">
              <template v-for="item in navigation" :key="item.name">
                <router-link
                  :class="[
                    active ? 'bg-akf-300 text-white' : 'text-white hover:bg-akf-400',
                    'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                  ]"
                  :to="item.linkName"
                >
                  <component :is="item.icon" class="flex-shrink-0 w-6 h-6 mr-4 text-akf-200" aria-hidden="true" />
                  {{ item.name }}
                </router-link>
              </template>
            </nav>
          </div>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div class="hidden bg-gray-800 dark:bg-gray-800 md:flex md:flex-shrink-0">
    <div class="flex flex-col w-64">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-4">
          <!-- <img
            class="w-auto h-14"
            src="@/assets/img/akf-logo.svg"
            alt="AKF Apps"
          /> -->
          <img class="w-auto mx-auto h-14" src="@/assets/img/akf-logo-white.png" alt="AKF Apps" />
        </div>
        <div class="flex flex-col flex-1 mt-8">
          <nav class="flex-1 px-2 space-y-1">
            <template v-for="item in navigation" :key="item.name">
              <router-link
                :class="[
                  active ? 'bg-akf-300 text-white' : 'text-white hover:bg-akf-400',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                ]"
                :to="item.linkName"
              >
                <component
                  :is="item.icon"
                  class="flex-shrink-0 w-6 h-6 mr-3 text-akf-200 dark:text-blue-400"
                  aria-hidden="true"
                />
                {{ item.name }}
              </router-link>
            </template>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";
  import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue";
  import { CalendarIcon, ChartBarIcon, HomeIcon, DocumentReportIcon, XIcon, CubeIcon } from "@heroicons/vue/outline";

  const navigation = [
    { name: "Dashboard", linkName: "/dashboard", icon: HomeIcon },
    { name: "SWOT Commando", linkName: "/swot", icon: DocumentReportIcon },
    { name: "RepGen", linkName: "/repgen", icon: ChartBarIcon },
    { name: "CubeGen", linkName: "/cubegen", icon: CubeIcon },
    { name: "Calendar", linkName: "/calendar", icon: CalendarIcon },
  ];

  export default {
    name: "sideBar",
    components: {
      Dialog,
      DialogOverlay,
      TransitionChild,
      TransitionRoot,
      XIcon,
    },
    setup() {
      const active = ref();

      return {
        navigation,
        active,
      };
    },
    computed: {
      sidebarOpen() {
        return this.$store.getters.getSideBarStatus;
      },
    },
    methods: {
      setSideBar(isOpen) {
        this.$store.dispatch("setSideBarStatus", isOpen);
      },
    },
  };
</script>

<style scoped>
  a.router-link-active,
  a.router-link-exact-active {
    @apply bg-akf-300 text-white;
  }
</style>
