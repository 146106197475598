<template>
  <div class="page-loader dark:bg-gray-700">
    <div class="cube"></div>
    <div class="cube"></div>
    <div class="cube"></div>
    <div class="cube"></div>

    <img class="w-auto h-36" src="@/assets/img/foom-loading.gif" alt="Loading" />
  </div>
</template>

<script>
  export default {
    components: {},
  };
</script>

<style scoped>
  .page-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #eee;
    z-index: 999;
  }
</style>
