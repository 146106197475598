import { initializeApp } from "firebase/app";
import "firebase/storage";
import { doc, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"

const swotApp = initializeApp({
    projectId: "akf-swot",
    apiKey: "AIzaSyDfhK3yQ8qxZTg-hlAuVQUh1gUKFRS6Yuk",
    authDomain: "akf-swot.firebaseapp.com",
    databaseURL: "https://akf-swot.firebaseio.com",
    storageBucket: "akf-swot.appspot.com",
    messagingSenderId: "967157966238",
    appId: "1:967157966238:web:c397c1b6a8f8c30199228f",
  });

export const db = getFirestore(swotApp);

export const repgenApp = initializeApp({
  apiKey: "AIzaSyAMs6TV2inC0CUuz3I17tQVw5PK922N5EY",
  authDomain: "akf-repgen-194a0.firebaseapp.com",
  projectId: "akf-repgen-194a0",
  storageBucket: "akf-repgen-194a0.appspot.com",
  messagingSenderId: "525745466569",
  appId: "1:525745466569:web:728e3b26d2825376203118"
}, "secondary");

export const repgen = getFirestore(repgenApp);

export const auth = getAuth(repgenApp);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe();
      resolve(user)
    }, reject)
  })
}

export const getUserDoc = async () => {
  const currentUser = await getCurrentUser()
		if(!currentUser) {
			return null
		}
		return doc(repgen, "employees", currentUser.uid)
}
