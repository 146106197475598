import VueCookie from "vue-cookie";
import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import msalConfig from "../config/msal";

class MicrosoftDataService {
	publicClientApplication = new PublicClientApplication(msalConfig);

	async getToken() {
		const token = VueCookie.get("microsoftToken");
		if(token) return token;
		const accessTokenRequest = {
			scopes: msalConfig.scope.msalScopes,
			account: this.publicClientApplication.getAllAccounts()[0]
		}
		if(accessTokenRequest.account === undefined) {
			await this.signIn()
			accessTokenRequest.account = this.PublicClientApplication.getAllAccounts()[0];
		}
		try {
			const tokenResponse = await this.publicClientApplication.acquireTokenSilent(accessTokenRequest);
			return this.saveToken(tokenResponse)
		} catch (error) {
			//Acquire token silent failure, and send an interactive request
			if (error instanceof InteractionRequiredAuthError) {
				try {
					const tokenResponse = await this.publicClientApplication.acquireTokenPopup(accessTokenRequest);
					this.saveToken(tokenResponse)
				} catch(error) {
					console.log(error);
				}
			} else {
				console.log(error)
			}
		}
	}

	async signIn() {
		await this.publicClientApplication.loginPopup({})
	}

	saveToken(tokenResponse) {
		VueCookie.set("microsoftToken", tokenResponse.accessToken, 0);
		return tokenResponse.accessToken;
	}

}

export default new MicrosoftDataService()