import { getCurrentUser } from "@/firebase";
import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    name: "Auth",
    path: "/Auth/Login",
    meta: {
      title: "Login",
      requiresAuth: false,
      noSave: true
    },
    component: () => import("@/views/auth/Login"),
  },
  {
    name: "Logout",
    path: "/Logout",
    meta: {
      title: "Logout",
      requiresAuth: false,
      noSave: true
    },
    component: () => import("@/views/auth/Logout"),
  },
  {
    name: "Home",
    path: "/",
    redirect: { name: "Dashboard" },
    meta: {
      title: "Home",
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard"),
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/client/:clientName/:engID?/:tab?",
    name: "clientSingle",
    component: () => import("@/views/DashboardClientSingle"),
    meta: {
      title: "Client Single",
      requiresAuth: true,
    },
  },
  {
    path: "/swot",
    name: "SWOT",
    component: () => import("@/views/swot/Index"),
    meta: {
      title: "SWOT",
      requiresAuth: true,
    },
  },
  {
    path: "/swot/About",
    name: "SwotAbout",
    component: () => import("@/views/swot/About"),
    meta: {
      title: "SWOT",
      requiresAuth: true,
    },
  },
  {
    path: "/swot/archived",
    name: "SwotArchived",
    component: () => import("@/views/swot/Archived"),
    meta: {
      title: "SWOT",
      requiresAuth: true,
    },
  },
  {
    path: "/swot/client/:clientID",
    name: "SwotClientSingle",
    component: () => import("@/views/swot/ClientSingle"),
    meta: {
      title: "SWOT Single",
      requiresAuth: true,
    },
  },
  {
    path: "/swot/archived/:clientID",
    name: "archiveSingle",
    component: () => import("@/views/swot/ArchiveSingle"),
    meta: {
      title: "SWOT",
      requiresAuth: true,
    },
  },
  {
    path: "/repgen",
    name: "RepGen",
    component: () => import("@/views/Repgen"),
    meta: {
      title: "Report Generation",
      requiresAuth: true
    },
  },
  {
    path: "/repgen/archive",
    name: "RepGenArchive",
    component: () => import("@/views/RepgenArchive"),
    meta: {
      title: "Repgen Archive",
      requiresAuth: true
    }
  },
  {
    path: "/repgen/client/:clientID",
    name: "clientRepgen",
    component: () => import("@/views/RepgenClient"),
    meta: {
      title: "Report Generation Client",
      requiresAuth: true,
    },
  },
  {
    path: "/cubegen",
    name: "CubeGen",
    component: () => import("@/views/Cubegen"),
    meta: {
      title: "Cube Generation",
      requiresAuth: true,
    },
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: () => import("@/views/Calendar"),
    meta: {
      title: "Calendar",
      requiresAuth: true,
    },
  },
  {
    path: "/clients",
    name: "Clients",
    component: () => import("@/views/Clients"),
    meta: {
      title: "Calendar",
      requiresAuth: true,
    },
  },
  {
    path: "/client/:clientID",
    name: "DashboardClientSingle",
    component: () => import("@/views/DashboardClientSingle"),
    meta: {
      title: "Single Client",
      requiresAuth: true,
    },
  },
  {
    path: "/callback/slack",
    name: "SlackCallback",
    component: () => import("@/views/SlackCallback"),
    meta: {
      title: "Callback",
      requiresAuth: false,
      noSave: true,
    },
  },
  {
    path: "/loading",
    name: "Loading",
    component: () => import("@/views/Blank"),
    meta: {
      title: "Loading",
      requiresAuth: false,
      noSave: true,
    },
  },
  {
    path: "/user/:userId",
    name: "Profile",
    component: () => import("@/views/Profile"),
    meta: {
      title: "Profile",
      requiresAuth: true
    }
  },
  // Not found page must be placed at the end !!!
  {
    name: "NotFound",
    path: "/:pathMatch(.*)*",
    meta: {
      title: "Error",
      requiresAuth: false,
      noSave: true
    },
    component: () => import("@/views/error/NotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// auth guard for routes
router.beforeEach(async function(to, _, next) {
  store.dispatch("setCurrRoute", to);
  store.dispatch("setSideBarStatus", false);
  if (to.meta.requiresAuth && !(await getCurrentUser())) {
    next({ name: "Auth" });
  } else {
    next();
  }
});

router.returnToSaved = function() {
  let currRoute = localStorage.getItem("currRoute");
  if (currRoute) {
    this.replace({ path: currRoute });
  } else {
    this.replace({ name: "Dashboard" });
  }
}

export default router;
