 <template>
    <div>
        <img :src="profilePic" class="h-8 w-8 rounded-full">
    </div>
 </template>

<script>
export default {
    data: () => ({
        profilePic: require('@/assets/img/avatar-hold.png')
    }),
    mounted() {
        
        this.$httpMS.get('/me/photos/48x48/$value', {
            responseType: 'blob'
        })
        .then(response => {
            this.profilePic = URL.createObjectURL(new Blob( [ response.data ], { type: "image/jpeg" } ));
        })
        .catch((err) => {
            console.log(err)
        })
        
    }
};
</script>

