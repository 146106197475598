export default {
    setCurrRoute(state, route) {
        state.currRoute = route;
    },
    setSideBarStatus(state, isOpen) {
        state.sidebarOpen = isOpen;
    },
    loading(state, isLoading) {
        state.isLoading = isLoading;
    },
    setError(state,payload) {
        console.log(payload.msg);
        state.errMsg = payload.msg
    }
  
  };