<template>
  <div class="">
    <the-header v-if="isLoggedIn"></the-header>
    <auth-header v-if="!isLoggedIn"></auth-header>
  </div>
</template>

<script>
  import { auth } from "@/firebase";
  import { onAuthStateChanged } from "@firebase/auth";
  import AuthHeader from "./components/layout/AuthHeader.vue";
  import TheHeader from "./components/layout/TheHeader.vue";
  export default {
    name: "App",
    components: {
      TheHeader,
      AuthHeader,
    },
    data() {
      return {
        isLoggedIn: !!auth.currentUser
      }
    },
    mounted() {
      if (
        localStorage.dark === "dark" ||
        (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
      ) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }

      onAuthStateChanged(auth, (user) => {
        this.isLoggedIn = !!user
      })
    },
    computed: {},
  };
</script>
